import {
  ICardResponse,
  ICardResponseV2,
  isValidArrayWithData,
  useQueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import { ICheckoutV2Response } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICheckoutV2'
import { IErrorNewBFF } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IErrorNewBFF'
import { CARD_TYPES, ERROR_MAP } from './contants'

export interface IPublishEvent {
  error: string
  endpoint: keyof typeof ERROR_MAP
}

export const useDataLayers = () => {
  const queryClient = useQueryClient()

  // get cache data
  let checkoutData: ICheckoutV2Response =
    queryClient.getQueryData('/checkoutV2')

  let cardsData: ICardResponse & ICardResponseV2 & IErrorNewBFF =
    queryClient.getQueryData('/cards')

  // get the previous data if there is an error
  if (checkoutData?.error) {
    checkoutData = queryClient.getQueryCache().find('/checkoutV2')?.revertState
      ?.data as typeof checkoutData
  }
  if (cardsData?.error) {
    cardsData = queryClient.getQueryCache().find('/cards')?.revertState
      ?.data as typeof cardsData
  }

  // parse data
  const paymentInfo = checkoutData?.paymentInfo?.selectedPayments?.[0]
  const paymentInfoWithCard = {
    ...paymentInfo,
    ...(cardsData?.cards?.find((card) => {
      return card?.id === paymentInfo?.cardId
    }) || {})
  }
  const isActiveGiftcard =
    isValidArrayWithData(checkoutData?.paymentInfo?.giftCards) &&
    checkoutData?.paymentInfo?.giftCards?.some((giftCard) => {
      return giftCard?.isActive
    })

  // build event to push
  const eventToPush = {
    event: 'page_view',
    tipo_pagina: 'Checkout Error',
    payment_type:
      paymentInfoWithCard?.gateway || (isActiveGiftcard ? 'Giftcard' : ''),
    card_type:
      CARD_TYPES[paymentInfoWithCard?.cardType] ||
      CARD_TYPES[paymentInfoWithCard?.type] ||
      (isActiveGiftcard ? 'Giftcard' : ''),
    error_value: 'GEN-001'
  }

  // function to push event
  const pushEvent = ({ error, endpoint }: IPublishEvent) => {
    if (typeof window === 'undefined') return

    const getError = ERROR_MAP[endpoint].find((errorMap) => {
      const regex = new RegExp(errorMap.message, 'gm')
      return regex.test(error)
    })

    if (getError) {
      eventToPush.error_value = getError.show_code
    }

    window.dataLayer.push(eventToPush)
  }

  return {
    pushEvent
  }
}
