import { useEffect, useState } from 'react'
import {
  cleanRut,
  formatRut,
  sanitizeRut,
  validateRut
} from '@smu-chile/pkg-unimarc-components/utils'
import {
  postSimulationPaymentUnipay,
  postUnipayValidateAccount,
  trigger,
  useCheckoutV2
} from '@smu-chile/pkg-unimarc-hooks'
import { IPostSimulationPaymentUnipayResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostSimulationPaymentUnipay'
import { useCheckoutConfig } from 'shared/hooks'
import {
  ERROR_MESSAGE_UNIPAY_4,
  ERROR_MESSAGE_UNIPAY_INSTALLMENTS,
  UNIPAY_EXCEPTIONS
} from 'shared/constants'

interface Values {
  rut: string
  installments: string
}

export type TRutStatus = '' | 'success' | 'error'

export interface IUseModalUnipayValidations {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCleanInput: (name: string) => void
  simulatePaymentData: IPostSimulationPaymentUnipayResponse
  isSimulationLoading: boolean
  isDisabledInstallments: boolean
  isMissingValuesForm: boolean
  isValidatingRut: boolean
  values: Values
  errors: Record<string, string>
  rutStatus: TRutStatus
}

export const useModalUnipayValidations = (): IUseModalUnipayValidations => {
  const [values, setValues] = useState<Values>({ rut: '', installments: '' })
  const [rutStatus, setRutStatus] = useState<TRutStatus>('')
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [isValidatingRut, setIsValidatingRut] = useState(false)
  const [isDisabledInstallments, setIsDisabledInstallments] = useState(true)
  const [simulatePaymentData, setSimulatePaymentData] = useState<
    IPostSimulationPaymentUnipayResponse | Record<string, never>
  >({})
  const [isSimulationLoading, setIsSimulationLoading] = useState(false)
  const [isMissingValuesForm, setIsMissingValuesForm] = useState(false)
  const { data: checkoutData } = useCheckoutV2()
  const { checkoutConfig } = useCheckoutConfig()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'rut') {
      if (cleanRut(e.target.value).length < 10) {
        setValues((prevValues) => {
          return {
            ...prevValues,
            [e.target.name]: formatRut(sanitizeRut(e.target.value))
          }
        })
      }
    } else {
      setValues((prevValues) => {
        return {
          ...prevValues,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  const handleCleanInput = (name: string) => {
    setValues({ ...values, [name]: '' })
    setSimulatePaymentData({})
    handleError(name, '')
  }

  const handleError = (field: string, value: string) => {
    setErrors((prev) => {
      return {
        ...prev,
        [field]: value
      }
    })
    setRutStatus(value ? 'error' : '')
  }
  const handleInstallmentsResets = (isMissingValuesForm: boolean) => {
    if (isMissingValuesForm) {
      // disable installments input
      setSimulatePaymentData({})
      setIsDisabledInstallments(true)
      setValues((prevValues) => {
        return {
          ...prevValues,
          installments: ''
        }
      })
      // disable modal unipay button
      setIsMissingValuesForm(isMissingValuesForm)
      return
    }
    // enable button
    setIsDisabledInstallments(false)
    setIsMissingValuesForm(isMissingValuesForm)
  }

  useEffect(() => {
    const missingValues =
      !values.rut ||
      !validateRut(values.rut) ||
      !values.installments ||
      isValidatingRut
    setIsMissingValuesForm(missingValues)
  }, [values, isValidatingRut])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (cleanRut(values.rut).length > 7 && validateRut(values.rut)) {
        setIsValidatingRut(true)
        handleError('rut', '')
        const responseValidation = await postUnipayValidateAccount({
          data: {
            rut: values.rut.replace(/\./g, ''),
            amount: checkoutData?.value,
            quantity: checkoutData?.items?.length
          }
        })
        if (responseValidation?.status === 204) {
          handleError('rut', '')
          setRutStatus('success')
          setIsDisabledInstallments(false)
          setValues((prevValues) => {
            return {
              ...prevValues,
              installments: checkoutConfig?.installments?.[0].text
            }
          })
        } else {
          const errorCode = responseValidation?.data?.code || ''
          const errorMessage =
            errorCode?.length > 0
              ? UNIPAY_EXCEPTIONS.find((exception) => {
                  return exception.code_errors.includes(
                    responseValidation?.data?.code
                  )
                })?.message
              : ''

          // set error message
          if (errorMessage) {
            handleError('rut', 'Ingresa un RUT válido')
          }
          // reset installments simulation values and
          // disable installments input
          handleInstallmentsResets(true)
          // clean rut input
          handleCleanInput('rut')
          // trigger toast with error
          trigger({
            eventType: 'checkoutToast',
            data: {
              portalRootId: 'portal-unipay',
              codeError: errorCode || 'unknow',
              type: 'error',
              show: true,
              toastMessage: errorMessage || ERROR_MESSAGE_UNIPAY_4
            }
          })
        }
        setIsValidatingRut(false)
      } else if (cleanRut(values.rut).length > 0) {
        handleError('rut', 'Ingresa un RUT válido')
        handleInstallmentsResets(true)
      } else {
        handleError('rut', '')
        setValues((prevValues) => {
          return {
            ...prevValues,
            installments: ''
          }
        })
        setIsDisabledInstallments(true)
      }
    }, 1000)

    return () => {
      return clearTimeout(delayDebounceFn)
    }
  }, [values.rut])

  // on handleChange use a useEffect to validate installments
  // and invoce the service postSimulatePaymentUnipay without delayDebounceFn
  useEffect(() => {
    const simulateQuotasOnHandleChange = async () => {
      // validate if installments is empty, like the first time
      // or when the user change the value
      if (values?.installments.length === 0) return
      // validate if installments is 'Sin cuotas'
      // and if so, set the simulatePaymentData to empty for
      // reset InstallmentSimulator component
      if (values?.installments === 'Sin cuotas') {
        setSimulatePaymentData({})
        return
      }
      // start to show to user that process is happening
      setIsSimulationLoading(true)
      // start to simulate the payment
      const responseValidation = await postSimulationPaymentUnipay({
        rut: values.rut.replace(/\./g, ''),
        amount: checkoutData?.value,
        quotas: parseInt(values.installments)
      })
      // if service is sucess (200) then
      if (responseValidation?.status === 200) {
        setSimulatePaymentData(responseValidation)
        setIsSimulationLoading(false)
        handleError('installments', '')
      } else {
        const errorCode = responseValidation?.code || ''
        const errorMessage =
          errorCode?.length > 0
            ? UNIPAY_EXCEPTIONS.find((exception) => {
                return exception.code_errors.includes(responseValidation?.code)
              })?.message
            : ''

        // reset installments simulation values and
        // disable installments input
        setSimulatePaymentData({})
        setValues((prevValues) => {
          return {
            ...prevValues,
            installments: ''
          }
        })
        // trigger toast with error
        trigger({
          eventType: 'checkoutToast',
          data: {
            portalRootId: 'portal-unipay',
            codeError: errorCode || 'unknow',
            type: 'error',
            show: true,
            toastMessage: errorMessage || ERROR_MESSAGE_UNIPAY_INSTALLMENTS
          }
        })
      }
      // reset the isSimulationLoading
      setIsSimulationLoading(false)
    }
    simulateQuotasOnHandleChange()
  }, [values.installments])

  return {
    handleChange,
    handleCleanInput,
    isSimulationLoading,
    simulatePaymentData,
    isDisabledInstallments,
    isMissingValuesForm,
    isValidatingRut,
    values,
    errors,
    rutStatus
  }
}
